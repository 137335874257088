<template>
  <div class="video-watch-content">
    <!-- <template v-if="complete">
      <div style="font-size: 200px">已完成</div>
    </template> -->
    <template v-if="undone">
      <div class="title" style="margin-top: 0">
        {{current && current.name}}
      </div>
      <div class="video-content">
        <div class="video-mask">
          <video controls id="video" :poster="poster" controlslist="nodownload" preload="none">
            <source src="" type="video/mp4">
          </video>
        </div>
      </div>
      <div class="title">观看视频后，请您在文本框中回答以下4个问题。</div>
      <div class="comment-content" v-show="show">
        <el-form :disabled="disabled"
                 :model="commentForm" :rules="rules" ref="commentForm">
          <template v-for="(value, key, index) in commentForm">
            <el-form-item :label="`问题${index + 1}: ${questions[key]}`" :prop="key" :key="key">
              <el-input type="textarea" :autosize="{ minRows: 8 }" v-model="commentForm[key]"
                        style="width: 100%"></el-input>
            </el-form-item>
          </template>
          <el-form-item v-if="!disabled">
            <el-button type="primary" :load="loading" @click="submitFn">提交</el-button>
            <el-button @click="clearFn">清空</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="index -= 1" :disabled="index === 0">上一题</el-button>
        <el-button type="primary" @click="index += 1" :disabled="(index + 1) === showList.length">
          下一题</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import comments from '@/api/comments';
import blank from '@/assets/blank.png';

export default {
  props: {
    category: String,
  },
  data() {
    return {
      src: '',
      poster: '',
      show: false,
      loading: false,
      disabled: false,
      complete: false,
      undone: false,
      flag: false,
      index: 0,
      current: null,
      video: null,
      blank,
      showList: [],
      questions: {},
      commentForm: {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
      rules: {},
    };
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          Object.assign(this.$data, this.$options.data());
          this.completeFn();
        });
      },
    },
    current: {
      deep: true,
      handler(val) {
        if (!val) return;
        const { url, isvideocomplete, iscommentcomplete } = val;
        this.$nextTick(() => { this.setForm(); });
        if (isvideocomplete === 1) {
          this.video.src = '';
          this.poster = this.blank;
        } else {
          // this.video.src = `http://47.94.225.94${url}`;
          this.video.src = `${window.location.origin}${url}`;
          this.poster = '';
        }
        this.disabled = iscommentcomplete === 1;
      },
    },
    index(val) {
      this.current = this.showList[val];
    },
  },
  mounted() {
    this.completeFn();
  },
  methods: {
    initFn() {
      const arr = [
        comments.show({ category: this.category }),
        comments.question(),
      ];
      Promise.all(arr).then(([{ data: showList }, { data: questions }]) => {
        if (showList && showList.length) {
          this.show = true;
          this.showList = showList;
          this.questions = questions;
          [this.current] = showList;
        } else this.$message.info('该用户尚未添加视频调查');
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
    constFn() {
      this.video = document.querySelector('#video');
    },
    addEventListenerFn() {
      this.video.addEventListener('ended', () => {
        comments.change(this.current.videoid).then(() => {
          this.current.isvideocomplete = 1;
        });
      });
      this.video.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    },
    completeFn() {
      comments.complete({
        category: this.category,
      }).then(({ data }) => {
        // 注释原来的，会显示已完成
        // this.complete = data;
        // this.undone = !data;
        // if (!data && !this.flag) {
        //   this.flag = true;
        //   this.$nextTick(() => {
        //     this.constFn();
        //     this.addEventListenerFn();
        //     this.initFn();
        //   });
        // }

        // 不会显示已完成
        // this.complete = data;
        this.undone = true;
        if (!this.flag) {
          this.flag = true;
          this.$nextTick(() => {
            this.constFn();
            this.addEventListenerFn();
            this.initFn();
          });
        }
      });
    },
    setForm() {
      Object.keys(this.commentForm).forEach((key) => {
        this.commentForm[key] = this.current[key];
        this.rules[key] = [{
          required: true,
          message: `请填写问题: ${this.questions[key]}`,
          trigger: 'blur',
        }];
      });
    },
    submitFn() {
      this.$refs.commentForm.validate((valid) => {
        if (valid) {
          const { videoid } = this.current;
          this.loading = true;
          comments.add({
            videoId: videoid,
            ...this.commentForm,
          }).then(() => {
            this.loading = false;
            Object.assign(this.current, { iscommentcomplete: 1, ...this.commentForm });
            this.completeFn();
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    clearFn() {
      this.$refs.commentForm.resetFields();
    },
  },
};
</script>

<style scoped lang="less">
  .video-watch-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .title {
      width: 100%;
      line-height: 36px;
      border-bottom: 1px solid #DCDFE6;
      margin: 22px 0;
    }
    .video-content {
      width: 80%;
      .video-mask {
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
        video {
          position: absolute;
          width: 100%;
          height: 100%;
          &:focus {
            outline: transparent;
          }
        }
      }
    }
    .comment-content {
      width: 100%;
    }
  }
</style>
